import { Dispatch, RefObject, SetStateAction } from 'react';

export const notifyPlayer = (
  iframeRef: RefObject<HTMLIFrameElement>,
  setState: Dispatch<SetStateAction<boolean>>,
  azurePlay: boolean
) => {
  const payload = {
    time: azurePlay ? 0.01 : 9999999,
    origin: 'https://www.videoindexer.ai',
  };

  if ('postMessage' in window) {
    setState(false);
    iframeRef?.current?.contentWindow?.postMessage(payload, payload.origin);
  }
};
