import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { mediaTablet } from 'utils/styles/responsive';

interface SoundIconWrapperProps {
  size?: number;
}

interface CircleStrokeProps {
  $isHover?: boolean;
}

export const SoundIconWrapper = styled.div<SoundIconWrapperProps>`
  width: 40px;
  height: 40px;
  position: relative;
  transform: scale(${({ size }) => (size ? size : 1)});
  cursor: pointer;

  ${mediaTablet(css`
    width: 64px;
    height: 64px;
  `)}
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Svg = styled.svg`
  width: 40px;
  height: 40px;
  display: block;
  margin: auto 0;
  transform: rotate(-90deg);
  transition: transform 1s ease-in-out;
  fill: rgba(0, 0, 0, 0.3);

  ${mediaTablet(css`
    width: 64px;
    height: 64px;
  `)}
`;

export const Circle = styled.circle``;

export const InsideBorderCircle = styled.circle`
  stroke-dasharray: 297 999;
`;

export const CircleStroke = styled.circle<CircleStrokeProps>`
  fill: none;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke: ${({ $isHover, theme }) =>
    $isHover ? theme.colors.white : 'transparent'};
  stroke-dasharray: 297 999;
`;

export const Image = styled(motion.img)`
  width: 14px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 10;

  ${mediaTablet(css`
    width: 21px;
    height: 16px;
  `)}
`;
