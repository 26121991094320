/* eslint simple-import-sort/imports: 0 */
/* eslint @typescript-eslint/no-non-null-assertion: 0 */
import { useRouter } from 'next/router';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';

import SoundIcon from 'components/SoundIcon/SoundIcon';
import VideoIndicator from 'components/VideoIndicator/VideoIndicator';
import {
  MultiSectionComponent,
  VideoSourceTypes,
} from 'utils/sharedStrapiQueries/sharedTypes';

import { notifyPlayer } from './VideoBlock.utils';

import * as S from './VideoBlock.styles';

import Player from '@vimeo/player';
import {
  setCursorLabel,
  triggerDisableCursor,
  triggerLightCursor,
} from 'utils/cursorController';
import { handleMouseLeave } from 'utils/cursorGlobal';
import { useGlobalStore } from 'store';
import useIsInView from 'hooks/useIsInView';

const VideoBlock: React.FunctionComponent<MultiSectionComponent> = ({
  media,
  videoErrorMessage = 'error',
  videoSource,
  videoSourceId,
  azureUserId,
  azurePlaceholder,
  autoplay,
  manualPlay,
  withSound,
  mute = false,
  vimeoBackground,
  disableCursor = false,
  threshold,
  disableInView = false,
  ...rest
}) => {
  const [isMuted, setIsMuted] = useState<boolean>(mute);
  const [isPause, setIsPause] = useState<boolean>(false);
  const [isAzurePlaceholder, setIsAzurePlaceholder] = useState<boolean>(true);
  const [azurePlay, setAzurePlay] = useState<boolean>(!autoplay);

  const { inView, targetRef } = useIsInView(threshold);

  const vimeoRef = useRef<HTMLIFrameElement>(null);
  const azureRef = useRef<HTMLIFrameElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const { isMenuOpen } = useGlobalStore();
  const router = useRouter();

  const placeholderCondition =
    azurePlaceholder && isAzurePlaceholder && !autoplay;

  const onHandlePlay = () => {
    setIsPause(false);
    videoRef.current!.play();
    videoRef.current!.controls = false;
  };

  const onHandlePause = useCallback(() => {
    setIsPause(true);
    videoRef.current!.pause();
  }, []);

  const handleMouseEnter = useCallback(() => {
    if (videoSource !== 'self_hosted' || disableCursor) return;
    setCursorLabel(isPause ? 'Play' : 'Pause');
    triggerLightCursor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPause]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!isMenuOpen && inView) handleMouseEnter();
    else handleMouseLeave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, isMenuOpen, isPause]);

  useEffect(() => {
    // In view autoplay functionality
    if (videoSource === 'self_hosted') {
      if (autoplay)
        inView || disableInView
          ? videoRef?.current!.play()
          : videoRef?.current!.pause();
    }

    if (videoSource === 'azure')
      notifyPlayer(azureRef, () => null, inView || disableInView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  useEffect(() => {
    if (vimeoRef.current) {
      const player = new Player(vimeoRef.current);

      player.setMuted(isMuted);

      const playPause = async () => {
        try {
          inView || disableInView ? await player.play() : await player.pause();
        } catch (e) {}
      };

      // In view autoplay functionality
      if (autoplay) playPause().then();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vimeoRef, isMuted, inView]);

  const renderSource = (videoSource: VideoSourceTypes) => {
    switch (videoSource) {
      case 'vimeo':
        return (
          <S.VimeoIframeWrapper>
            {videoSourceId && (
              <S.Iframe
                onMouseEnter={triggerDisableCursor}
                ref={vimeoRef}
                src={`https://player.vimeo.com/video/${videoSourceId}?autoplay=${autoplay}&loop=1&muted=${mute}&autopause=0&controls=${!autoplay}&background=${vimeoBackground}`}
                frameBorder="0"
                allowFullScreen
                allow="autoplay; picture-in-picture; encrypted-media"
                title="Vimeo iframe"
              />
            )}
          </S.VimeoIframeWrapper>
        );
      case 'azure':
        return (
          azureUserId &&
          videoSourceId && (
            <S.AzureWrapper
              onClick={() => (
                setAzurePlay(!azurePlay),
                notifyPlayer(azureRef, setIsAzurePlaceholder, azurePlay)
              )}
            >
              {placeholderCondition && (
                <S.AzurePlaceholder imageSrc={azurePlaceholder?.url || ''} />
              )}

              <S.AzureScrollBlocker />

              <S.AzureIframe
                onMouseEnter={triggerDisableCursor}
                ref={azureRef}
                src={`https://www.videoindexer.ai/embed/player/${azureUserId}/${videoSourceId}/?locale=${
                  router.locale
                }&autoplay=${(azurePlaceholder?.url && !autoplay) || autoplay}`}
                frameBorder="0"
                allowFullScreen
                allow="autoplay; picture-in-picture; encrypted-media"
                title="Azure iframe"
              />
            </S.AzureWrapper>
          )
        );
      default:
        return (
          <S.Video
            ref={videoRef}
            onClick={isPause ? onHandlePlay : onHandlePause}
            muted={isMuted}
            loop
            autoPlay={autoplay}
            preload="auto"
            playsInline
          >
            <source src={media?.url || ''} type="video/mp4" />
            {videoErrorMessage}
          </S.Video>
        );
    }
  };

  return (
    // TODO: ASPECT-RATIO IN WRAPPER PROPS
    <S.MediaWrapper
      ref={targetRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      {...rest}
    >
      {renderSource(videoSource)}
      {videoSource === 'self_hosted' && (
        <>
          {manualPlay && (
            <S.IndicatorWrapper
              onMouseEnter={handleMouseLeave}
              onMouseLeave={handleMouseEnter}
            >
              <VideoIndicator
                isPause={isPause}
                onPlay={onHandlePlay}
                onPause={onHandlePause}
                size={0.35}
              />
            </S.IndicatorWrapper>
          )}
        </>
      )}
      {videoSource !== 'azure' && withSound && (
        <S.IconIndicatorWrapper
          isVimeo={videoSource === 'vimeo'}
          onMouseEnter={handleMouseLeave}
          onMouseLeave={handleMouseEnter}
        >
          <SoundIcon isMuted={isMuted} onClick={() => setIsMuted(!isMuted)} />
        </S.IconIndicatorWrapper>
      )}
    </S.MediaWrapper>
  );
};

export default memo(VideoBlock);
