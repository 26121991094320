import styled, { css } from 'styled-components';

const PlayIcon = '/public/svgs/play.svg';

import { aspectRatioConverter } from 'utils';
import { AspectRatio } from 'utils/sharedStrapiQueries/sharedTypes';
import { mediaTablet } from 'utils/styles/responsive';

export const MediaWrapper = styled.div<{ aspectRatio?: AspectRatio }>`
  position: relative;
  width: 100%;
  overflow: hidden;
  display: grid;
  object-fit: cover;
  aspect-ratio: ${({ aspectRatio }) =>
    aspectRatioConverter(aspectRatio || '_16x9')};

  & iframe {
    aspect-ratio: ${aspectRatioConverter('_16x9')};
  }
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Indicators = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  ${mediaTablet(css`
    width: 64px;
    height: 64px;
  `)}
`;

export const IndicatorWrapper = styled(Indicators)`
  left: 0;
  margin: 0 0 10px 10px;
`;

export const IconIndicatorWrapper = styled(Indicators)<{ isVimeo?: boolean }>`
  right: 0;
  margin: ${({ isVimeo }) => (isVimeo ? '0 30px 10px 0' : '0 10px 10px 0')};
`;

export const Iframe = styled.iframe`
  height: inherit;
  position: absolute;
  top: 50%;
  left: 50%;
  object-fit: fill;
  transform: translate(-50%, -50%);
`;

export const AzureIframe = styled.iframe`
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  object-fit: fill;
  transform: translate(-50%, -50%);
  overflow-y: hidden;
`;

export const VimeoIframeWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  padding: 56.25% 0 0 0;
`;

export const AzureWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
`;

export const AzurePlaceholder = styled.div<{ imageSrc: string }>`
  width: 100%;
  height: 100%;
  background: url(${({ imageSrc }) => imageSrc}) no-repeat center center / cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  cursor: pointer;

  &::before {
    content: '';
    width: 50px;
    height: 66px;
    position: absolute;
    inset: 0;
    margin: auto;
    background: url(${PlayIcon}) no-repeat center center / cover;
  }
`;

export const AzureScrollBlocker = styled.div`
  width: 100%;
  height: calc(100% - 53px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;
