import { motion } from 'framer-motion';
import styled from 'styled-components';

interface LineProps {
  iconColor?: string;
}

interface WrapperProps {
  backgroundColor?: string;
}

export const Wrapper = styled(motion.div)<WrapperProps>`
  width: 56px;
  height: 56px;
  display: grid;
  place-items: center;
  overflow: hidden;
  border-radius: 50%;
  background: ${({ backgroundColor }) => backgroundColor};
  position: relative;
  cursor: pointer;
`;

export const Line = styled.span<LineProps>`
  width: 16px;
  position: absolute;
  height: 2px;
  background: ${({ iconColor }) => iconColor};
  overflow: hidden;
`;

export const LineTop = styled(Line)`
  transform: rotate(45deg);
`;

export const LineBottom = styled(Line)`
  transform: rotate(-45deg);
`;
