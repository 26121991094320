import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';

import { imageVariants } from './VideoIndicator.animations';

import * as S from './VideoIndicator.styles';

export interface VideoIndicatorProps {
  timer?: number;
  isPause: boolean;
  onPlay: (e: any) => void;
  onPause: (e: any) => void;
  size: number;
}

const VideoIndicator: React.FunctionComponent<VideoIndicatorProps> = ({
  timer,
  isPause = true,
  onPlay,
  onPause,
  size,
  ...rest
}) => {
  const [isHover, setIsHover] = useState<boolean>(false);

  const handleClick = (e: any) => (isPause ? onPlay(e) : onPause(e));

  return (
    <S.VideoIndicatorWrapper
      size={size}
      {...rest}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <S.Container onClick={handleClick}>
        <AnimatePresence>
          {isPause ? (
            <S.Image
              id="svg-video-icon"
              key="Play"
              {...imageVariants}
              src="/svgs/play.svg"
              alt="play button"
              $isPause={isPause}
            />
          ) : (
            <S.Image
              id="svg-video-icon"
              key="Pause"
              {...imageVariants}
              src="/svgs/pause.svg"
              alt="pause button"
              $isPause={isPause}
            />
          )}
        </AnimatePresence>
        <S.Svg
          viewBox="0 0 100 100"
          data-percent="89"
          preserveAspectRatio="xMidYMid meet"
          xmlns="http://www.w3.org/2000/svg"
        >
          <S.Circle
            cx="50"
            cy="50"
            r="49.5"
            onClick={isPause ? onPlay : onPause}
          />
          <S.InsideBorderCircle
            cx="50"
            cy="50"
            r="47.5"
            fill="none"
            stroke={isHover && isPause ? 'white' : '#878787ff'}
            strokeWidth="2"
            strokeLinecap="round"
            strokeDasharray="297 999"
          />
          {timer && (
            <S.CircleStroke
              $timer={timer}
              $isPause={isPause}
              cx="50"
              cy="50"
              r="47.5"
              strokeDasharray="297 999"
            />
          )}
        </S.Svg>
      </S.Container>
    </S.VideoIndicatorWrapper>
  );
};

export default VideoIndicator;
