import {
  Ref,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useGlobalStore } from 'store';
import { isOverElement } from 'utils';

type UseOverElement = [Ref<HTMLDivElement>, boolean];

const useOverElement = (
  refOut?: RefObject<Element>,
  observer = true,
  isThreshold = true
): UseOverElement => {
  const [isInView, setIsInView] = useState<boolean>(false);
  const { isEnteringWorkPageContent, setIsHeaderTextVisible } =
    useGlobalStore();

  const ref = useRef<HTMLDivElement>(null);

  const onScroll = useCallback((headerElement, targetElement) => {
    if (headerElement && targetElement) {
      const isOver = isOverElement(headerElement, targetElement, isThreshold);

      setIsInView(prevIsInView => {
        if (isOver !== prevIsInView) {
          return isOver;
        }
        return prevIsInView;
      });
    }
  }, []);

  useEffect(() => {
    setIsHeaderTextVisible(!isInView);
  }, [isEnteringWorkPageContent, isInView]);

  useEffect(() => {
    if (observer) {
      const headerElement = document.getElementById('header_container');
      const targetElement = refOut?.current || ref?.current;

      const handleOnScroll = () => onScroll(headerElement, targetElement);

      handleOnScroll();
      window.addEventListener('scroll', handleOnScroll);

      return () => window.removeEventListener('scroll', handleOnScroll);
    }
  }, [refOut, ref]);

  return [ref, false];
};

export default useOverElement;
