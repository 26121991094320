import { useRef, useState } from 'react';
import { useEffectOnce } from 'usehooks-ts';

const useIsInView = (threshold?: number) => {
  const [inView, setIsInView] = useState(false);
  const targetRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = () => {
    if (targetRef.current) {
      const rect = targetRef.current.getBoundingClientRect();

      const normalCondition =
        rect.top >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight);

      const thresholdCondition =
        rect.top <= threshold && rect.bottom >= threshold - 100;

      const inViewCondition =
        typeof threshold === 'number' ? thresholdCondition : normalCondition;

      setIsInView(inViewCondition);
    }
  };

  useEffectOnce(() => {
    window.addEventListener('scroll', handleScroll);
    setTimeout(handleScroll, 1500);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return { inView, targetRef };
};

export default useIsInView;
