interface CloseIconOpenProps {
  delay: number;
}

export const CloseIconWrapperVariants = {
  open: ({ delay }: CloseIconOpenProps) => ({
    opacity: [0, 1],
    transition: { delay: delay, duration: 0.5, ease: 'easeInOut' },
  }),
  close: {
    opacity: [1, 0],
    transition: { duration: 0.5, ease: 'easeInOut' },
  },
};
