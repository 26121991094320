import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';

import { imageVariants } from './SoundIcon.animations';

import * as S from './SoundIcon.styles';

export interface SoundIconProps {
  isMuted: boolean;
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  size?: number;
}

const SoundIcon: React.FunctionComponent<SoundIconProps> = ({
  isMuted,
  onClick,
  onMouseEnter = () => null,
  onMouseLeave = () => null,
  size,
  ...rest
}) => {
  const [isHover, setIsHover] = useState<boolean>(false);

  const handleMouseEnter = () => {
    onMouseEnter();
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    onMouseLeave();
    setIsHover(false);
  };

  return (
    <S.SoundIconWrapper
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      size={size}
      {...rest}
    >
      <S.Container>
        <AnimatePresence>
          <S.Image
            id="svg-video-icon"
            key={'image-' + isMuted}
            {...imageVariants}
            src={isMuted ? '/svgs/sound-off.svg' : '/svgs/sound-on.svg'}
            alt="svg"
          />

          <S.Svg
            viewBox="0 0 100 100"
            data-percent="89"
            preserveAspectRatio="xMidYMid meet"
            xmlns="http://www.w3.org/2000/svg"
          >
            <S.Circle cx="50" cy="50" r="49.5" />
            <S.InsideBorderCircle
              cx="50"
              cy="50"
              r="47.5"
              fill="none"
              strokeWidth="2"
              strokeLinecap="round"
              stroke="#878787ff"
            />
            <S.CircleStroke $isHover={isHover} cx="50" cy="50" r="47.5" />
          </S.Svg>
        </AnimatePresence>
      </S.Container>
    </S.SoundIconWrapper>
  );
};

export default SoundIcon;
