import React, { FunctionComponent, useEffect, useRef } from 'react';

import { aspectRatioConverter } from 'utils';
import {
  setCursorLabel,
  triggerDefaultCursor,
  triggerLightCursor,
} from 'utils/cursorController';
import {
  AspectRatio,
  MediaCMS,
  MediaType,
} from 'utils/sharedStrapiQueries/sharedTypes';

import * as S from './ContentMedia.styles';

export interface ContentMediaProps {
  media: MediaCMS;
  mediaType: MediaType;
  aspectRatio?: AspectRatio;
  autoplay?: boolean;
  mute?: boolean;
  videoErrorMessage?: string;
}

const defaultProps: Partial<ContentMediaProps> = {
  media: {
    url: '',
    alternativeText: '',
  },
  mediaType: 'image',
};

const ContentMedia: FunctionComponent<ContentMediaProps> = ({
  mediaType,
  media,
  aspectRatio,
  autoplay,
  mute,
  videoErrorMessage,
  ...rest
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (autoplay && videoRef?.current) videoRef.current.play();
  }, [autoplay]);

  const handleMouseEnter = () => {
    setCursorLabel(autoplay ? 'Pause' : 'Play');
    triggerLightCursor();
  };

  const handleMouseLeave = () => {
    setCursorLabel('');
    triggerDefaultCursor();
  };

  const image = (
    <S.Image
      src={media?.url || ''}
      alt={media?.alternativeText || ''}
      loading="lazy"
    />
  );

  const video = (
    <S.Video
      ref={videoRef}
      muted={mute}
      loop
      autoPlay={autoplay}
      preload="auto"
      playsInline
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <source src={media?.url || ''} type="video/mp4" />
      {videoErrorMessage || ''}
    </S.Video>
  );

  if (!mediaType) return null;

  return (
    <S.Wrapper aspectRatio={aspectRatioConverter(aspectRatio)} {...rest}>
      {mediaType === 'image' ? image : video}
    </S.Wrapper>
  );
};

ContentMedia.defaultProps = defaultProps;

export default ContentMedia;
