import { motion } from 'framer-motion';
import styled from 'styled-components';

import CloseIcon, { CloseIconProps } from 'components/CloseIcon/CloseIcon';
import { setVh } from 'utils/styles/mixins';
import { mediaDesktop } from 'utils/styles/responsive';
import { colors, layout } from 'utils/styles/theme';

interface WrapperProps {}

interface ContainerProps {
  withSpacing: boolean;
}

interface CloseIconCompProps extends CloseIconProps {
  withSpacing: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  pointer-events: all !important;
  width: 100vw;
  ${setVh(100)};

  display: flex;
  justify-content: flex-end;

  position: fixed;
  inset: 0;
  z-index: ${layout.zIndex.overlay};

  overflow: auto;

  *::selection {
    background: ${colors.black};
    color: ${colors.white};
    text-shadow: none;
  }

  *::-moz-selection {
    background: ${colors.black};
    color: ${colors.white};
    text-shadow: none;
  }
`;

export const Background = styled(motion.div)`
  width: 100%;
  height: 100%;

  position: fixed;
  inset: 0;

  background-color: ${colors.black};
`;

export const Container = styled(motion.aside)<ContainerProps>`
  width: 100%;
  height: 100%;

  position: relative;

  background-color: ${colors.white};
  overflow-y: auto;

  ${({ withSpacing }) =>
    mediaDesktop(`
      width: 56.31%;
      min-width: 300px;
      ${
        withSpacing &&
        `
        width: min-content;
        min-width: auto;

        margin: 56px 124px 56px auto;
        height: min-content;
      `
      };
    `)}
`;

export const CloseIconComp = styled(CloseIcon)<CloseIconCompProps>`
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: ${layout.zIndex.menuModal};

  ${({ withSpacing }) =>
    mediaDesktop(`
      top: 32px;
      right: 32px;
      ${withSpacing ? 'top: 56px' : 'top: 32px'};
      right: 32px;
    `)};
`;
