import styled, { css } from 'styled-components';

interface WrapperProps {
  aspectRatio: string | boolean;
}

export const SharedStyles = css`
  max-width: 100%;
  max-height: 100%;
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  object-position: center;

  display: block;
`;

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  height: 100%;
  display: block;

  ${({ aspectRatio }) => aspectRatio && `aspect-ratio: ${aspectRatio}`};
`;

export const Image = styled.img`
  ${SharedStyles}
`;

export const Video = styled.video`
  ${SharedStyles}
`;
