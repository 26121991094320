import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { mediaTablet } from 'utils/styles/responsive';

interface VideoIndicatorWrapperProps {
  size: number;
}

interface CircleStrokeProps {
  $timer?: number;
  $isPause: boolean;
}

export const VideoIndicatorWrapper = styled.div<VideoIndicatorWrapperProps>`
  width: 40px;
  height: 40px;
  position: relative;
  cursor: pointer;

  ${mediaTablet(css`
    width: 64px;
    height: 64px;
  `)}
`;

export const Svg = styled.svg`
  width: 40px;
  height: 40px;
  display: block;
  margin: auto 0;
  transform: rotate(-90deg);
  transition: transform 1s ease-in-out;
  fill: rgba(0, 0, 0, 0.3);
  z-index: 1;

  ${mediaTablet(css`
    width: 64px;
    height: 64px;
  `)}
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const Circle = styled.circle`
  cursor: pointer;
`;

export const InsideBorderCircle = styled.circle`
  stroke-dasharray: 297 999;
`;

export const CircleStroke = styled.circle<CircleStrokeProps>`
  fill: none;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke: ${({ $isPause, theme }) =>
    $isPause ? 'transparent' : theme.colors.white};
  stroke-dasharray: 297 999;

  ${({ $timer, $isPause }) =>
    !$isPause &&
    css`
      animation: countdown ${$timer}ms linear infinite;
    `}

  @keyframes countdown {
    from {
      stroke-dashoffset: 297px;
    }

    to {
      stroke-dashoffset: 0;
    }
  }
`;

export const Image = styled(motion.img)<{ $isPause?: boolean }>`
  width: ${({ $isPause }) => ($isPause ? 9 : 6)}px;
  height: ${({ $isPause }) => ($isPause ? 9 : 11)}px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  ${({ $isPause }) => $isPause && 'transform: translateX(1px) !important'};
  cursor: pointer;
  z-index: 10;
  ${({ $isPause }) =>
    mediaTablet(css`
      width: ${$isPause ? 14 : 11}px;
      height: ${$isPause ? 14 : 16}px;
      ${$isPause && 'transform: translateX(2px) !important'};
    `)}
`;
