import React from 'react';

import { colors } from 'utils/styles/theme';

import { CloseIconWrapperVariants } from './CloseIcon.animations';

import * as S from './CloseIcon.styles';

export interface CloseIconProps {
  isOpen?: boolean;
  iconColor?: string;
  backgroundColor?: string;
  delay?: number;
  onClick?: () => void;
}

const defaultProps: Partial<CloseIconProps> = {
  isOpen: true,
  delay: 0,
  iconColor: colors.lighterMineShaft,
  backgroundColor: colors.concrete,
};

const CloseIcon: React.FunctionComponent<CloseIconProps> = ({
  isOpen,
  delay = 0,
  iconColor,
  backgroundColor,
  ...rest
}) => {
  return (
    <S.Wrapper
      animate={isOpen ? 'open' : 'close'}
      exit="close"
      backgroundColor={backgroundColor}
      variants={CloseIconWrapperVariants}
      custom={{ delay: delay }}
      {...rest}
    >
      <S.LineTop iconColor={iconColor} />
      <S.LineBottom iconColor={iconColor} />
    </S.Wrapper>
  );
};

CloseIcon.defaultProps = defaultProps;

export default CloseIcon;
