import styled from 'styled-components';

import ContentMedia from 'components/ContentMedia/ContentMedia';
import RichText from 'components/RichText/RichText';
import { ReactComponent as SVGLinkArrow } from 'svgs/obliqueArrow.svg';
import { setTypography } from 'utils/styles/mixins';
import { mediaDesktop } from 'utils/styles/responsive';
import { colors, layout } from 'utils/styles/theme';

interface WrapperProps {}

interface RelatedContentProps {
  noColumns?: boolean;
}

interface MediaProps {
  insights?: boolean;
}

const { min, max } = layout.columns;

export const Wrapper = styled.div<WrapperProps>`
  display: block;
  position: relative;

  overflow-x: hidden;
`;

export const Container = styled.div`
  display: grid;
  grid-row-gap: 72px;

  padding: 88px 16px 121px;

  & * {
    color: ${colors.lighterMineShaft};
  }

  ${mediaDesktop(`
    padding: 144px 122px 170px;
  `)};
`;

export const Heading = styled.section`
  display: grid;
  grid-row-gap: 12px;

  ${mediaDesktop(`
    grid-row-gap: 16px;
  `)};
`;

export const RichTextComp = styled(RichText)`
  ${({ theme: { locale } }) => setTypography('body', locale)};
`;

export const RelatedWrapper = styled.section`
  display: grid;

  ${mediaDesktop(`
    margin-top: 16px;
  `)};
`;

export const RelatedHeading = styled.div`
  padding: 0 0 12px;

  border-bottom: 1px solid ${colors.black};
`;

export const RelatedContent = styled.ul<RelatedContentProps>`
  display: grid;
  ${({ noColumns }) =>
    !noColumns &&
    `
    margin-top: 16px;
    grid-row-gap: 48px;
  `}

  ${({ noColumns }) =>
    mediaDesktop(`
   ${
     !noColumns &&
     `
      grid-gap: 32px;
      grid-template-columns: repeat(${min}, 1fr);
   `
   }`)};
`;

export const Media = styled(ContentMedia)<MediaProps>`
  ${({ insights }) => insights && 'height: min-content'};
  margin-bottom: 8px;

  grid-column: 1 / 5;
`;

export const CaseStudiesItem = styled.li`
  & > div {
    aspect-ratio: 3 / 2;
  }
`;

export const LinkArrow = styled(SVGLinkArrow)`
  display: none;
  transition: opacity 0.3s ease-in-out;

  ${mediaDesktop(`
    width: 16px;
    position: absolute;
    top: 32px;
    right: 0;

    display: block;

    opacity: 0;
  `)}
`;

export const ListItem = styled.li`
  position: relative;

  padding: 28px 0;
  border-bottom: 1px solid ${colors.black};

  a {
    display: grid;
    grid-column-gap: 24px;
    grid-template-columns: repeat(${max}, 1fr);
  }

  ${mediaDesktop(`
    a {
      grid-column-gap: 32px;
    }

    &:hover {
      ${LinkArrow} {
        opacity: 1
      }
    }
  `)};
`;

export const TextContainer = styled.div`
  display: grid;
  grid-row-gap: 12px;

  grid-column: 5 / ${max + 1};

  ${mediaDesktop(`
    grid-column: 5 / 11;
  `)};
`;
