import { AnimatePresence } from 'framer-motion';
import React, { ForwardedRef, FunctionComponent, useState } from 'react';
import ReactDOM from 'react-dom';
import { useEffectOnce } from 'usehooks-ts';

import {
  opacityMotionProps,
  overlayMotionProps,
  overlayOpacityProps,
} from 'utils/styles/animations';

import * as S from './Overlay.styles';

/*  **** EXAMPLE USAGE ****
      <Overlay isOpen={isOpen} onExitClick={() => setIsOpen(false)}>
        <p>Example content</p>
      </Overlay>
*/

export interface OverlayProps {
  children: React.ReactNode;
  isOpen?: boolean;
  onExitClick?: () => void;
  withSpacing?: boolean;
  CloseButton?: FunctionComponent;
  delay?: number;
  animation?: 'slide' | 'fade';
}

const defaultProps: Partial<OverlayProps> = {
  isOpen: false,
  children: null,
  CloseButton: S.CloseIconComp,
  delay: 1,
  animation: 'slide',
};

const Overlay = React.forwardRef<HTMLDivElement, OverlayProps>(
  (
    {
      children,
      isOpen,
      onExitClick,
      withSpacing,
      CloseButton,
      delay,
      animation,
      ...rest
    },
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffectOnce(() => {
      setIsLoaded(true);
    });

    if (!isLoaded) return null;

    const animations = {
      slide: overlayMotionProps,
      fade: overlayOpacityProps,
    };

    return ReactDOM.createPortal(
      <AnimatePresence initial>
        {isOpen && (
          <S.Wrapper {...rest}>
            <S.Background
              {...opacityMotionProps({ duration: 0.4, max: 0.7 })}
            />
            <CloseButton
              // @ts-ignore
              delay={delay}
              onClick={onExitClick}
              withSpacing={withSpacing}
            />
            <S.Container
              ref={ref}
              withSpacing={withSpacing}
              {...animations[animation]}
            >
              {children}
            </S.Container>
          </S.Wrapper>
        )}
      </AnimatePresence>,
      document.body
    );
  }
);

Overlay.defaultProps = defaultProps;
Overlay.displayName = 'Overlay';

export default Overlay;
