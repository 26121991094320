import React, { FunctionComponent } from 'react';

import LinkComp from 'components/LinkComp/LinkComp';
import Text from 'components/Text/Text';
import { ROUTES } from 'utils/routes';
import {
  ExpertiseCategoryCMS,
  ExpertiseCategoryListItemCMS,
} from 'utils/sharedStrapiQueries/sharedTypes';

import * as S from './CapabilitiesOverlay.styles';

type ExpertiseProps = Omit<
  ExpertiseCategoryCMS,
  'categoryTitle' | 'listItems'
> &
  ExpertiseCategoryListItemCMS;

export interface CapabilitiesOverlayProps extends ExpertiseProps {}

const defaultProps: Partial<CapabilitiesOverlayProps> = {
  label: '',
  overlaySectionTitle: '',
  overlayRichText: '',
  insights: [],
  caseStudies: [],
  relatedInsights: { sectionTitle: '' },
  relatedCaseStudies: { sectionTitle: '', cursorLabelView: '' },
};

const CapabilitiesOverlay: FunctionComponent<CapabilitiesOverlayProps> = ({
  label,
  overlayTitle,
  overlayRichText,
  insights,
  caseStudies,
  relatedInsights,
  relatedCaseStudies,
  ...rest
}) => {
  const headingContent = (
    <S.Heading>
      <Text as="h5" size="heading5" text={label} />
      <Text as="h2" size="heading2" text={overlayTitle} />
    </S.Heading>
  );

  const relatedCaseStudiesSection = (
    <S.RelatedWrapper>
      <S.RelatedHeading>
        <Text
          as="small"
          size="smallBody"
          text={relatedCaseStudies?.sectionTitle}
        />
      </S.RelatedHeading>

      <S.RelatedContent>
        {caseStudies.map(({ id, title, image, case_study_page }) => (
          <S.CaseStudiesItem key={id}>
            <LinkComp href={`${ROUTES.WORK}/${case_study_page.slug}`}>
              <S.Media media={image} mediaType="image" />
              <Text as="h5" size="heading5" text={title} />
            </LinkComp>
          </S.CaseStudiesItem>
        ))}
      </S.RelatedContent>
    </S.RelatedWrapper>
  );

  const relatedInsightsSection = (
    <S.RelatedWrapper>
      <S.RelatedHeading>
        <Text
          as="small"
          size="smallBody"
          text={relatedInsights?.sectionTitle}
        />
      </S.RelatedHeading>

      <S.RelatedContent noColumns>
        {insights.map(
          ({ id, title, subtitle, news_article, insights_article }) => {
            const condition = news_article ? news_article : insights_article;
            const filtered =
              condition?.modules?.filter(
                ({ __typename }) =>
                  __typename === 'ComponentSectionsArticleHero'
              ) || [];

            return (
              <S.ListItem key={id}>
                <LinkComp
                  href={`${news_article ? 'news' : ROUTES.INSIGHTS}/${
                    condition?.slug || '#'
                  }`}
                >
                  <S.Media
                    media={filtered[0]?.image || null}
                    mediaType="image"
                    insights
                  />
                  <S.TextContainer>
                    <Text as="h5" size="heading5" text={title} />
                    <Text
                      size="smallBody"
                      text={
                        subtitle.length > 71
                          ? subtitle.slice(0, 72) + '...'
                          : subtitle
                      }
                    />
                  </S.TextContainer>
                  <S.LinkArrow />
                </LinkComp>
              </S.ListItem>
            );
          }
        )}
      </S.RelatedContent>
    </S.RelatedWrapper>
  );

  return (
    <S.Wrapper {...rest}>
      <S.Container>
        {headingContent}

        <S.RichTextComp richText={overlayRichText} defaultSpacing />

        {relatedCaseStudiesSection}
        {relatedInsightsSection}
      </S.Container>
    </S.Wrapper>
  );
};

CapabilitiesOverlay.defaultProps = defaultProps;

export default CapabilitiesOverlay;
